@media (min-width: 1200px) {
	.container {
		max-width: 93% !important;
	}
}
@media (min-width: 1300px) {

	.journey_title_explanation{
		padding: 30px 50px;
		line-height: 30px;
	}

	.container {
	  max-width: 1280px;
	}
  }
  .product-detail {
	.product-title{
		font-size: 16px; font-weight: 600; color: #212529;
	}
}
  
  /*@media (min-width: 992px) {
	  .navbar-toggler {
		  display: none;
	  }
	  .btn.close-btn {
		  display: none;
	  }
	  
  }*/
  @media (min-width: 991px) {
	.journey_title_explanation{
		padding: 30px 50px;
		line-height: 30px;
	}
	.trers-grid-image {
	  img {
		max-width: 150px;
	  }
	}
	.title-spacing-mob{
		margin-top: -60px;
	  }
	  .card-ali{
		    color: #fff;
    border-radius: 0px !important;
    background-color: #222!important;
	margin: -5px 0px 0px -21px;
    padding: 4px 0px 0px 0px;
	  }
	  .table-responsive{
		width: 122% !important;
	  }
	  .card-value{
		margin: 12px 5px 0px -23px;
	  }
	  .product-li{
		width:100%;
		white-space: nowrap;
		 overflow: hidden;
		  text-overflow: ellipsis;
		   text-align: center;
	}

	.product-li-1{
		width:100%;
		white-space: nowrap;
		 overflow: hidden;
		  text-overflow: ellipsis;
		   text-align: center;

	}

	// .popover{
	// 	margin: -58px -8px 0 0 !important;
	// }
  }
  
  @media (max-width: 1199px) {
	footer{
        .footer-links {
            display: block;
        }
    }
	.free-shipping-section {
	  ul {
		flex-flow: wrap;
		li {
		  margin-bottom: 15px;
		}
	  }
	}
	body {
	  padding-top: 47px;
	}
  
	.header {
	  .flex-container {
		.header-row-one {
		  display: block;
		  .account-section {
			position: absolute;
			top: 0;
			width: 100%;
			border-bottom: 1px solid #00000012;
			left: 0;
			z-index: 99;
			background: #fff;
			.account {
			  justify-content: flex-end;
    		 margin-top: 10px;
			}
		  }
		}
	  }
	}
  }
  @media (max-width: 991px) {

	.product-details-wrapper {
		.slider-nav {
			bottom:6px;
			
		}
		.details-wrapper {
			
			.product-all-details {
				.row {
					.col-left {
						flex: auto;
					}
				}
				.product-addtional-details{
					padding-bottom:15px;
					&:before {
						width:100%;
					}
				}
			}
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 30px;
		  }
		}
	  }
	/**Product**/
	.product-box-inner {
	  .product-image {
		max-width: 150px;
	  }
	  .product-detail {
		.product-title{
			font-size: 16px; font-weight: 600; color: #212529;
		}
		.price-wrapper {
		  position: inherit;
		  transform: none;
		}
		.add-to {
		  position: inherit;
		}
	  }
	  .verified {
		top: 105px!important;
    	left: 300px!important;
    	width: 100px;
	  }
	}
  
	.best-products {
	  .row-fluid {
		display: block;
		.tyers-grid {
		  justify-content: space-between;
		  li {
			display: inline-block;
			margin: 15px 0px 15px 0px;
			text-align: center;
		  }
		}
	  }
	  .column {
		&.single-image {
		  margin-top: 15px;
		}
	  }
	}
  }
  
  @media (max-width: 767px) {

	.mob-filter{
		
		z-index: 200;
		background-color: $bg-color;
		width: 100%;
		top: 0;
    	bottom:0;
		left:0;
    	position:fixed;
    	overflow-y:scroll;
    	overflow-x:hidden;
	}

	.filter-button{
		display: block !important;
		position: fixed;
    	bottom: 4%;
    	left: 45%;
   		width: 50%;
    	height: 8%;
		border-radius: 50px;
		border: 2px solid $primary;
		font-size: 18px;
		font-weight: 600;
		-webkit-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		-moz-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
	}

	

	.details-wrapper {
		padding-right: 0px !important;
    	padding-left: 0px !important;
    	margin-right: 0px !important;
    	margin-left: 0px !important;
		.slider-nav {
			bottom:6px;
			max-width: 240px;
			min-width: 230px;
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 20px;
		  }
		}
	  }
	.aside-group {
	  display: block;
	}
  
	/**Title**/
	.content-title {
	  .title-box {
		.title {
		  &:after {
			display: none;
		  }
		}
	  }
	  
	}
  
	.header {
	  .flex-container {
		display: block;
		.logo {
		  text-align: center;
		  padding: 15px;
		  margin:0px auto;
		}
		.header-row-one {
			.search-bar-group {
				.search-bar {
					margin-top:10px;
					margin-right: -4rem;
					width:100% !important;
					
				}
			}
		}
	  }
	}
  
	.content-1-section {
	  position: static;
	  border-radius: 0px;
	}
	.homepage-banner-slider {
	  .owl-theme {
		.owl-dots {
		  bottom: 0;
		}
	  }
	}
  
	.best-products {
	  .column {
		.tyers-grid {
		  li {
			display: block;
			text-align: center;
		  }
		}
	  }
	}
  }
  @media (max-width: 640px) {

	
	.journey_title_explanation{
		padding: 30px 10px;
		line-height: 30px;
	}

	.content-title {
		.title-spacing-mob{
		  margin-top: -30px !important;
		}
	  }

	  .checkout-btn{
		min-width:fit-content; 
		padding-left:0px !important; 
		height:30px !important;
		position: relative;
		top: 0px;
		width: 150px !important;
		// margin-top: 16%;
		margin: 31px 0 0 5px;
	}

	.content-title{
		padding-top: 0px !important;
	}
	.center-title{
		padding-top: 10px !important;
	}

	.collection-wrapper {
	  .col-grid-box {
		padding: 0px 10px 0px 10px;
	  }
	}
	.name-form {
		margin-top: 7px!important;
	}
	footer{
        .footer-links {
            display: grid !important;
        }
		.footer-col{
			justify-content: center !important;
			padding: 5px;
			margin:5px;
			min-width:136%;
		}
    }	

	.banner-content {
	  .content {
		h2, h1 {
		  font-size: 18px;
		}
	  }
	}
	.header {
	  .flex-container {
		.header-row-one {
		  .search-bar {
			margin-left: 0px;
		  }
		}
	  }
	}
	.product-box-inner {
	  display: flex !important;
	  padding: 10px 0px 10px 0 !important;
	  height: 150px !important;
	  margin-left: -20px !important;
	  .img-wrapper {
		transform: scale(0.6) !important;
		
	  }
  
	  .product-detail {
		padding-left: 0px !important;
		height: 125px !important;
		.product-title{
			font-size: 12px; font-weight: 400; color: #212529;
		}
	  }
	  .verified {
		top: 10px;
		transform: none;
	  }
	}
  
	/*Pagenation**/
	.product-pagination {
	  .row {
		display: block;
		> div {
		  margin-bottom: 10px;
		  text-align: center;
		  p {
			margin-bottom: 0px;
		  }
		  .pagination {
			justify-content: center;
		  }
		}
	  }
	}
	.header {
		.flex-container {
		  .header-row-one {
			.search-bar {
			  margin-left: 0px;
			}
			.account-section{
			  position: fixed !important;
			  z-index: 2 !important;
			  padding-right:0px;
			  .account{
				  margin-right: 10px !important;
			  }
			  .dropdown-toggle{
				  font-size:10px !important;
			  }
			  .fa-user{
				  font-size:10px !important
			  }
			}
		  }
		}
	  }

  }
  /*** Range **/
  
  @media (max-width: 991px) and (min-width: 640px) {
	.product-name {
	  font-size: 18px;
	}
	.journey_title_explanation{
    padding: 30px 50px;
    line-height: 30px;
}
	.price {
	  font-size: 18px;
	}
  
	.product-price {
	  .tax-includes {
		font-size: 13px;
	  }
	}
	.verified {
	  font-size: 13px;
	}
	.add-to {
	  .btn-solid {
		font-size: 15px;
	  }
	}
  }

  .home-container-card{
	grid-auto-rows: auto 1fr!important;
}
  